import { t } from "i18next";
import closeIcon from "../../../../../../assets/close-svg.svg";

import styles from "./RemunerationModal.module.scss";

const RemunerationModal = ({ onClose, show, handleSubmit }) => {
  const HandleCloseModal = () => {
    onClose();
  };
  return (
    <>
      {show && (
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <button type="button" className={styles.modal_close_button} onClick={onClose}>
              <img className={styles.closeIcon_size} src={closeIcon} alt="closeIcon" />
            </button>
            <div className={styles.separator_top} />
            <div className={styles.verification}>
              <p>{t("app.suivezVosContratsPartner.QuestionRem")}</p>
              <ul>
                <li>{t("app.suivezVosContratsPartner.QuestionRemAge")}</li>
                <li>{t("app.suivezVosContratsPartner.QuestionReamSituAvContrat")}</li>
                <li>{t("app.suivezVosContratsPartner.QuestionRemAnneeForma")}</li>
                <li>{t("app.suivezVosContratsPartner.QuestionRemConvCollective")}</li>
              </ul>
              <p>
                {t("app.suivezVosContratsPartner.NoticeAide.part1")}
                <a
                  href="https://entreprises.omneseducation.com/statics/Nemo_Infographie_Remuneration_Omnes_Education_V2.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("app.suivezVosContratsPartner.NoticeAide.part2")}
                </a>
              </p>
              <div className={styles.opco_modal_text_wrapper}>
                <p>{t("app.suivezVosContratsPartner.OPCOAide.part1")}</p>
                <p>{t("app.suivezVosContratsPartner.OPCOAide.part2")}</p>
              </div>
            </div>
            <div className={styles.separator_bottom} />

            <div className={styles.button_container}>
              <div
                className={styles.true_btn}
                onClick={() => {
                  handleSubmit();
                  onClose();
                }}
                onKeyDown={(e) => e.key === "Enter" && HandleCloseModal()}
                role="button"
                tabIndex={0}
              >
                <p>{t("app.suivezVosContratsPartner.ValidRem")}</p>
              </div>
              <div
                className={styles.false_btn}
                onClick={() => HandleCloseModal()}
                onKeyDown={(e) => e.key === "Enter" && HandleCloseModal()}
                role="button"
                tabIndex={0}
              >
                <p>{t("app.suivezVosContratsPartner.VerifierInfos")}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RemunerationModal;
