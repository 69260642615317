import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";

import { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectContractDataFYC,
  patchContractDataThunk,
} from "app/slices/followYourContracts/followYourContracts.slice";

import { formatDate } from "utils/formatDate";
import { createObjAucun } from "utils/localReferentials";

import { changeAucunToNull, changeOuiOrNonToBoolean, changeEmptyStringToNull } from "utils/tools";
import SubTitleInForm from "components/typography/SubTitleInForm/SubTitleInForm";
import { toastError, toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";
import { FormInputField } from "pages/ENT/FollowYourContracts/components/formFields/FormInputField/FormInputField";
import InformationTextInForm from "components/typography/InformationTextInForm/InformationTextInForm";
import { YearFields } from "../components/YearFields";
import styles from "../Form8.module.scss";
import RemunerationModal from "../components/RemunerationModal";

function yearsBetweenDates(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  let years = end.getFullYear() - start.getFullYear();

  // Vérifier si on doit ajuster l'année en fonction des mois et des jours
  const months = end.getMonth() - start.getMonth();
  const days = end.getDate() - start.getDate();

  // Si les mois sont négatifs ou si les mois sont zéros et les jours négatifs, cela signifie que nous n'avons pas encore atteint l'anniversaire complet
  if (months < 0 || (months === 0 && days < 0)) {
    years -= 1;
  }

  if (years >= 0) {
    years += 1;
  }

  return years;
}

function sameDayNextYear(date) {
  const originalDate = new Date(date);

  originalDate.setFullYear(originalDate.getFullYear() + 1);

  // Formater la date au format YYYY-MM-DD
  const formattedDate = originalDate.toISOString().split("T")[0];
  return formattedDate;
}
function dayBeforeNextYear(date) {
  const originalDate = new Date(date);
  const nextYearDate = new Date(originalDate.setFullYear(originalDate.getFullYear() + 1));
  nextYearDate.setDate(nextYearDate.getDate() - 1);

  // Formater la date au format YYYY-MM-DD
  const formattedDate = nextYearDate.toISOString().split("T")[0];
  return formattedDate;
}

function SubForm8Apprenticeship({
  axiosCancelToken,
  submittingAllSections,
  submitNextSectionIfSubmitAllTrue,
  cancelSubmitAllSections,
  currentSectionBeingSubmitted,
  setFormIsValid,
}) {
  const contractData = useSelector(selectContractDataFYC);
  const lastYearSituation = contractData?.student?.currentAcademicProfile?.lastYearSituation?.key;

  const [yearOfChange, setYearOfChange] = useState(null);
  const [showRemunerationModal, setShowRemunerationModal] = useState(false);
  const [contractPeriods, setContractPeriods] = useState([]);

  const dispatch = useDispatch();
  const objAucun = createObjAucun();

  // Yup validation schema
  const Form8Schema = Yup.object().shape({
    grossSalary: Yup.string().required(`${t("app.errorMessages.RemplirChamp")}`),
    Year1Start: Yup.date().required(t("app.errorMessages.RemplirChamp")),
    Year1Before: Yup.date()
      // .typeError(t("app.errorMessages.FormatDateInvalide"))
      .when([], () => (yearOfChange === 1 ? Yup.date().required(t("app.errorMessages.RemplirChamp")) : Yup.date().nullable())),
    Year1After: Yup.date()
      // .typeError(t("app.errorMessages.FormatDateInvalide"))
      .when([], () => (yearOfChange === 1 ? Yup.date().required(t("app.errorMessages.RemplirChamp")) : Yup.date().nullable())),
    Year1End: Yup.date().required(t("app.errorMessages.RemplirChamp")),
    salaryPercentage1: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    salaryPercentage1Bonus: Yup.string().when([], () => (yearOfChange === 1 ? Yup.string().required(t("app.errorMessages.RemplirChamp")) : Yup.string())),

    salaryType1: Yup.object().test(
      "if-is-aucun",
      t("app.errorMessages.RemplirChamp"),
      (value) => value.key !== "-Aucun-",
    ),

    salaryType1Bonus: Yup.object().when([], {
      is: () => yearOfChange === 1,
      then: (schema) => schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    Year2Start: Yup.date().when([], () => (contractPeriods.length >= 2 ? Yup.date().required(t("app.errorMessages.RemplirChamp")) : Yup.date().nullable())),
    Year2Before: Yup.date().when([], () => (contractPeriods.length >= 2 && yearOfChange === 2
      ? Yup.date().required(t("app.errorMessages.RemplirChamp"))
      : Yup.date().nullable())),
    Year2After: Yup.date().when([], () => (contractPeriods.length >= 2 && yearOfChange === 2
      ? Yup.date().required(t("app.errorMessages.RemplirChamp"))
      : Yup.date().nullable())),
    Year2End: Yup.date().when([], () => (contractPeriods.length >= 2 ? Yup.date().required(t("app.errorMessages.RemplirChamp")) : Yup.date().nullable())),
    salaryPercentage2: Yup.string().when([], () => (contractPeriods.length >= 2 ? Yup.string().required(t("app.errorMessages.RemplirChamp")) : Yup.string())),
    salaryPercentage2Bonus: Yup.string().when([], () => (contractPeriods.length >= 2 && yearOfChange === 2
      ? Yup.string().required(t("app.errorMessages.RemplirChamp"))
      : Yup.string())),
    salaryType2: Yup.object().when([], {
      is: () => contractPeriods.length >= 2,
      then: (schema) => schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    salaryType2Bonus: Yup.object().when([], {
      is: () => contractPeriods.length >= 2 && yearOfChange === 2,
      then: (schema) => schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    Year3Start: Yup.date().when([], () => (contractPeriods.length >= 3 ? Yup.date().required(t("app.errorMessages.RemplirChamp")) : Yup.date().nullable())),
    Year3Before: Yup.date().when([], () => (contractPeriods.length >= 3 && yearOfChange === 3
      ? Yup.date().required(t("app.errorMessages.RemplirChamp"))
      : Yup.date().nullable())),
    Year3After: Yup.date().when([], () => (contractPeriods.length >= 3 && yearOfChange === 3
      ? Yup.date().required(t("app.errorMessages.RemplirChamp"))
      : Yup.date().nullable())),
    Year3End: Yup.date().when([], () => (contractPeriods.length >= 3 ? Yup.date().required(t("app.errorMessages.RemplirChamp")) : Yup.date().nullable())),
    salaryPercentage3: Yup.string().when([], () => (contractPeriods.length >= 3 ? Yup.string().required(t("app.errorMessages.RemplirChamp")) : Yup.string())),
    salaryPercentage3Bonus: Yup.string().when([], () => (contractPeriods.length >= 3 && yearOfChange === 3
      ? Yup.string().required(t("app.errorMessages.RemplirChamp"))
      : Yup.string())),
    salaryType3: Yup.object().when([], {
      is: () => contractPeriods.length >= 3,
      then: (schema) => schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
    salaryType3Bonus: Yup.object().when([], {
      is: () => contractPeriods.length >= 3 && yearOfChange === 3,
      then: (schema) => schema.test("if-is-aucun", t("app.errorMessages.RemplirChamp"), (value) => value.key !== "-Aucun-"),
    }),
  });

  const onSubmit = async (values) => {
    const valuesCopy = structuredClone(values);

    // These functions change the value of certain keys depending on the swagger, before dataToSend initialization
    changeAucunToNull(valuesCopy);
    changeOuiOrNonToBoolean(valuesCopy);
    changeEmptyStringToNull(valuesCopy);

    const dataToSend = {
      status: "En cours",
      contract: {
        apprenticeshipType: contractData?.contract?.apprenticeshipType?.key,
        remuneration: {
          grossSalary: parseFloat(valuesCopy?.grossSalary),
          periods: [],
        },
      },
    };
    dataToSend.contract.remuneration.periods.push({
      code: "YEAR1",
      startDate: valuesCopy.Year1Start ? formatDate(valuesCopy.Year1Start) : null,
      endDate: valuesCopy.Year1End ? formatDate(valuesCopy.Year1End) : null,
      grossSalaryPercent: parseFloat(valuesCopy.salaryPercentage1),
      salaryType: valuesCopy?.salaryType1?.key,
    });

    if (yearOfChange === 1) {
      dataToSend.contract.remuneration.periods.push({
        code: "YEAR1_CHGT_AGE",
        startDate: valuesCopy.Year1Before ? formatDate(valuesCopy.Year1Before) : null,
        endDate: valuesCopy.Year1After ? formatDate(valuesCopy.Year1After) : null,
        grossSalaryPercent: parseFloat(valuesCopy.salaryPercentage1Bonus),
        salaryType: valuesCopy?.salaryType1Bonus?.key,
      });
    }
    if (contractPeriods.length >= 2) {
      dataToSend.contract.remuneration.periods.push({
        code: "YEAR2",
        startDate: valuesCopy.Year2Start ? formatDate(valuesCopy.Year2Start) : null,
        endDate: valuesCopy.Year2End ? formatDate(valuesCopy.Year2End) : null,
        grossSalaryPercent: parseFloat(valuesCopy.salaryPercentage2),
        salaryType: valuesCopy?.salaryType2?.key,
      });
    }
    if (contractPeriods.length >= 2 && yearOfChange === 2) {
      dataToSend.contract.remuneration.periods.push({
        code: "YEAR2_CHGT_AGE",
        startDate: valuesCopy.Year2Before ? formatDate(valuesCopy.Year2Before) : null,
        endDate: valuesCopy.Year2After ? formatDate(valuesCopy.Year2After) : null,
        grossSalaryPercent: parseFloat(valuesCopy.salaryPercentage2Bonus),
        salaryType: valuesCopy?.salaryType2Bonus?.key,
      });
    }
    if (contractPeriods.length >= 3) {
      dataToSend.contract.remuneration.periods.push({
        code: "YEAR3",
        startDate: valuesCopy.Year3Start ? formatDate(valuesCopy.Year3Start) : null,
        endDate: valuesCopy.Year3End ? formatDate(valuesCopy.Year3End) : null,
        grossSalaryPercent: parseFloat(valuesCopy.salaryPercentage3),
        salaryType: valuesCopy?.salaryType3?.key,
      });
    }
    if (contractPeriods.length >= 3 && yearOfChange === 3) {
      dataToSend.contract.remuneration.periods.push({
        code: "YEAR3_CHGT_AGE",
        startDate: valuesCopy.Year3Before ? formatDate(valuesCopy.Year3Before) : null,
        endDate: valuesCopy.Year3After ? formatDate(valuesCopy.Year3After) : null,
        grossSalaryPercent: parseFloat(valuesCopy.salaryPercentage3Bonus),
        salaryType: valuesCopy?.salaryType3Bonus?.key,
      });
    }

    try {
      await dispatch(
        patchContractDataThunk({
          dataToSend,
          id: contractData.id,
          axiosCancelToken,
        }),
      ).unwrap();

      submitNextSectionIfSubmitAllTrue(9);

      if (!submittingAllSections) {
        toastSuccess(<ToastMessageStructure secondMessage={t("app.toastMessages.ModifRemuContratApp")} />);
      }
    } catch (error) {
      cancelSubmitAllSections();
      toastError(
        <ToastMessageStructure firstMessage={t("app.toastMessages.PreContraEntSectionRemunerationSubmitError")} secondMessage={error.message} />,
      );
    }
  };

  const findDate = (date) => {
    if (date !== undefined) {
      return new Date(date);
    }
    return null;
  };
  const findCode =
  (code) => contractData?.contract?.remuneration?.periods?.find((remuneration) => remuneration.code === code);
  const { values, errors, touched, setFieldValue, handleSubmit, isValid, validateForm } = useFormik({
    initialValues: {
      Year1Start: findDate(contractData?.contract?.startDate),
      Year1Before: findDate(findCode("YEAR1_CHGT_AGE")?.startDate),
      Year1After: findDate(findCode("YEAR1_CHGT_AGE")?.endDate),
      Year1End: findDate(findCode("YEAR1")?.endDate),
      salaryPercentage1: findCode("YEAR1")?.grossSalaryPercent || "",
      salaryType1: findCode("YEAR1")?.salaryType || { ...objAucun },
      salaryPercentage1Bonus: findCode("YEAR1_CHGT_AGE")?.grossSalaryPercent || "",
      salaryType1Bonus: findCode("YEAR1_CHGT_AGE")?.salaryType || { ...objAucun },
      Year2Start: findDate(findCode("YEAR2")?.startDate),
      Year2Before: findDate(findCode("YEAR2_CHGT_AGE")?.startDate),
      Year2After: findDate(findCode("YEAR2_CHGT_AGE")?.endDate),
      Year2End: findDate(findCode("YEAR2")?.endDate),
      salaryPercentage2: findCode("YEAR2")?.grossSalaryPercent || "",
      salaryType2: findCode("YEAR2")?.salaryType || { ...objAucun },
      salaryPercentage2Bonus: findCode("YEAR2_CHGT_AGE")?.grossSalaryPercent || "",
      salaryType2Bonus: findCode("YEAR2_CHGT_AGE")?.salaryType || { ...objAucun },
      Year3Start: findDate(findCode("YEAR3")?.startDate),
      Year3Before: findDate(findCode("YEAR3_CHGT_AGE")?.startDate),
      Year3After: findDate(findCode("YEAR3_CHGT_AGE")?.endDate),
      Year3End: findDate(findCode("YEAR3")?.endDate),
      salaryPercentage3: findCode("YEAR3")?.grossSalaryPercent || "",
      salaryType3: findCode("YEAR3")?.salaryType || { ...objAucun },
      salaryPercentage3Bonus: findCode("YEAR3_CHGT_AGE")?.grossSalaryPercent || "",
      salaryType3Bonus: findCode("YEAR3_CHGT_AGE")?.salaryType || { ...objAucun },
      grossSalary: contractData?.contract?.remuneration?.grossSalary || "",
    },
    validationSchema: Form8Schema,
    onSubmit,
  });
  useLayoutEffect(() => {
    const { startDate, endDate } = contractData.contract;
    const numberOfYears = yearsBetweenDates(startDate, endDate) >= 3 ? 3 : yearsBetweenDates(startDate, endDate);
    const periods = [];

    let currentStartDate = startDate;

    for (let year = 1; year <= numberOfYears; year += 1) {
      const isFinalYear = year === numberOfYears;
      const yearEndDate = isFinalYear ? endDate : dayBeforeNextYear(currentStartDate);
      setFieldValue(`Year${year}Start`, new Date(currentStartDate));
      setFieldValue(`Year${year}End`, new Date(yearEndDate));

      periods.push({
        startDate: currentStartDate,
        endDate: yearEndDate,
      });

      if (!isFinalYear) {
        currentStartDate = sameDayNextYear(currentStartDate);
      }
    }
    setContractPeriods(periods);
  }, [contractData, setFieldValue]);

  // useLayoutEffect to force Formik's isValid check, the first time the component is mounted
  useLayoutEffect(() => {
    validateForm();
  }, [values]);

  // useLayoutEffect to control the color of the DropdownSection circle.
  useLayoutEffect(() => {
    if (isValid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [isValid]);

  // useLayoutEffect to manage the trigger at the right time for sending this form, during global submission.
  useLayoutEffect(() => {
    const thisFormNumber = 8;

    if (isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
    } else if (!isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
      cancelSubmitAllSections();
      toastError(<ToastMessageStructure firstMessage={t("app.toastMessages.PreContraEntSectionRemunerationNonRemplieError")} />);
    }
  }, [isValid, submittingAllSections, currentSectionBeingSubmitted]);
  return (
    <>
      <RemunerationModal
        show={showRemunerationModal}
        handleSubmit={handleSubmit}
        onClose={() => {
          setShowRemunerationModal(false);
        }}
        setFieldValue={setFieldValue}
      />
      <form onSubmit={handleSubmit}>
        <div>
          <InformationTextInForm>
            {t("app.suivezVosContratsPartner.Text1PageDaide")}
            <a
              style={{ textDecoration: "underline" }}
              href="/statics/Nemo_Infographie_Remuneration_Omnes_Education_V2.pdf"
              target="_blank"
            >
              {t("app.suivezVosContratsPartner.HyperLinkPageDaide")}
            </a>
          </InformationTextInForm>
          <SubTitleInForm additionalClassNames={styles.subtitle_in_form}>
            {t("app.suivezVosContratsPartner.PrevisionRemuneration")}
          </SubTitleInForm>
          <InformationTextInForm>
            {t("app.suivezVosContratsPartner.Text1Smic")}
            <a
              style={{ textDecoration: "underline" }}
              href="https://www.insee.fr/fr/statistiques/1375188"
              target="_blank"
              rel="noreferrer"
            >
              {t("app.suivezVosContratsPartner.HyperLinkSmic")}
            </a>
            <br />
            {t("app.suivezVosContratsPartner.Text2Smic")}
          </InformationTextInForm>
        </div>
        <div className={styles.row_container_gross}>
          <FormInputField
            id="grossSalary"
            errors={errors.grossSalary}
            touched={touched.grossSalary}
            values={values.grossSalary}
            onChange={(e) => setFieldValue("grossSalary", e.target.value)}
            label={t("app.suivezVosContratsPartner.SalaireBrutMensuelEmbauche")}
            type="number"
            additionalClassNames={styles.input_form8}
          />
        </div>
        {lastYearSituation === "Etudiant en apprentissage" && (
          <InformationTextInForm>{t("app.suivezVosContratsPartner.InfoSalaire.Apprentissage")}</InformationTextInForm>
        )}
        {!lastYearSituation && (
          <InformationTextInForm>{t("app.suivezVosContratsPartner.InfoSalaire.Inconnu")}</InformationTextInForm>
        )}
        {contractPeriods.map((contractPeriod, index) => (
          <YearFields
            // eslint-disable-next-line react/no-array-index-key
            key={`Year${index}`}
            values={values}
            errors={errors}
            touched={touched}
            index={index + 1}
            setFieldValue={setFieldValue}
            contractPeriod={contractPeriod}
            numberOfYears={contractPeriods.length}
            setYearOfChange={(year) => {
              setYearOfChange(year);
            }}
          />
        ))}
        <div className={styles.submit_btn_to_right}>
          <button
            type="button"
            onClick={() => {
              if (Object.keys(errors).length > 0) {
                handleSubmit();
              } else {
                setShowRemunerationModal(true);
              }
            }}
            className={styles.submit_form_btn}
          >
            {t("app.visualisationOffre.ValidezLesModifications")}
          </button>
        </div>
      </form>
    </>
  );
}

export default SubForm8Apprenticeship;
