import { t } from "i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLayoutEffect } from "react";
import {
  selectContractDataFYC,
  patchContractDataThunk,
} from "app/slices/followYourContracts/followYourContracts.slice";
import { FormInputField } from "pages/ENT/FollowYourContracts/components/formFields/FormInputField/FormInputField";
import SubmitFormBtn from "components/SubmitFormBtn/SubmitFormBtn";
import InformationTextInForm from "components/typography/InformationTextInForm/InformationTextInForm";
import { toastError, toastSuccess } from "utils/toasts";
import ToastMessageStructure from "components/ToastMessageStructure/ToastMessageStructure";

import styles from "../Form8.module.scss";

const SubForm8Other = ({
  axiosCancelToken,
  submittingAllSections,
  submitNextSectionIfSubmitAllTrue,
  cancelSubmitAllSections,
  currentSectionBeingSubmitted,
  setFormIsValid,
}) => {
  const dispatch = useDispatch();
  const contractData = useSelector(selectContractDataFYC);

  const lastYearSituation = contractData?.student?.currentAcademicProfile?.lastYearSituation?.key;

  const onSubmit = async () => {
    const dataToSend = {
      status: "En cours",
      contract: {
        apprenticeshipType: contractData?.contract?.apprenticeshipType?.key,
        employeeLevel: values.employeeLevel,
        hierarchicalCoefficient: values.hierarchicalCoefficient,
        remuneration: {
          grossSalary: parseFloat(values.grossSalary),
        },
      },
    };

    try {
      await dispatch(
        patchContractDataThunk({
          dataToSend,
          id: contractData.id,
          axiosCancelToken,
        }),
      ).unwrap();

      submitNextSectionIfSubmitAllTrue(9);

      if (!submittingAllSections) {
        toastSuccess(<ToastMessageStructure secondMessage={t("app.toastMessages.ModifRemuContratApp")} />);
      }
      console.log("Form8 patchContractDataThunk success !", dataToSend);
    } catch (error) {
      cancelSubmitAllSections();
      toastError(
        <ToastMessageStructure firstMessage={t("app.toastMessages.PreContraEntSectionRemunerationSubmitError")} secondMessage={error.message} />,
      );
    }
  };

  const Form8Schema = Yup.object({
    grossSalary: Yup.string().required(t("app.errorMessages.RemplirChamp")),
    employeeLevel: Yup.string().nullable().max(100),
    hierarchicalCoefficient: Yup.string().nullable().max(50),
  });

  const { values, errors, touched, setFieldValue, handleSubmit, isValid, validateForm } = useFormik({
    initialValues: {
      grossSalary: contractData?.contract?.remuneration?.grossSalary || "",
      employeeLevel: contractData?.contract?.employeeLevel || "",
      hierarchicalCoefficient: contractData?.contract?.hierarchicalCoefficient || "",
    },
    validationSchema: Form8Schema,
    onSubmit,
  });

  // useLayoutEffect to force Formik's isValid check, the first time the component is mounted
  useLayoutEffect(() => {
    validateForm();
  }, []);

  // useLayoutEffect to control the color of the DropdownSection circle.
  useLayoutEffect(() => {
    if (isValid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [isValid]);

  // useLayoutEffect to manage the trigger at the right time for sending this form, during global submission.
  useLayoutEffect(() => {
    const thisFormNumber = 8;

    if (isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
    } else if (!isValid && submittingAllSections && currentSectionBeingSubmitted === thisFormNumber) {
      handleSubmit();
      cancelSubmitAllSections();
      toastError(<ToastMessageStructure firstMessage={t("app.toastMessages.PreContraEntSectionRemunerationNonRemplieError")} />);
    }
  }, [isValid, submittingAllSections, currentSectionBeingSubmitted]);

  return (
    <>
      <div>
        <InformationTextInForm>
          {t("app.suivezVosContratsPartner.Text1Smic")}
          <a
            style={{ textDecoration: "underline" }}
            href="https://www.insee.fr/fr/statistiques/1375188"
            target="_blank"
            rel="noreferrer"
          >
            {t("app.suivezVosContratsPartner.HyperLinkSmic")}
          </a>
          <br />
          {t("app.suivezVosContratsPartner.Text2Smic")}
        </InformationTextInForm>
        <InformationTextInForm additionalClassNames={styles.information_text_in_form}>
          {t("app.suivezVosContratsPartner.Text1PageDaide")}
          <a
            style={{ textDecoration: "underline" }}
            href="/statics/Nemo_Infographie_Remuneration_Omnes_Education_V2.pdf"
            target="_blank"
          >
            {t("app.suivezVosContratsPartner.HyperLinkPageDaide")}
          </a>
        </InformationTextInForm>
      </div>
      <form onSubmit={handleSubmit}>
        <FormInputField
          id="grossSalary"
          errors={errors.grossSalary}
          touched={touched.grossSalary}
          values={values.grossSalary}
          onChange={(e) => setFieldValue("grossSalary", e.target.value)}
          label={t("app.suivezVosContratsPartner.SalaireBrutMensuelEmbauche")}
          type="number"
          step="0.01"
          className={styles.input_form8}
          additionalClassNames={styles.less_margin_bottom}
        />
        {lastYearSituation === "Etudiant en apprentissage" && (
          <InformationTextInForm>{t("app.suivezVosContratsPartner.InfoSalaire.Apprentissage")}</InformationTextInForm>
        )}
        {!lastYearSituation && (
          <InformationTextInForm>{t("app.suivezVosContratsPartner.InfoSalaire.Inconnu")}</InformationTextInForm>
        )}
        <InformationTextInForm additionalClassNames={styles.information_text_in_form}>
          {t("app.suivezVosContratsPartner.RemunerationContratPro.part1")}
          <br />
          {t("app.suivezVosContratsPartner.RemunerationContratPro.part2")}
        </InformationTextInForm>
        <FormInputField
          id="employeeLevel"
          errors={errors.employeeLevel}
          touched={touched.employeeLevel}
          values={values.employeeLevel}
          onChange={(e) => setFieldValue("employeeLevel", e.target.value)}
          label={t("app.suivezVosContratsPartner.Niveau")}
        />
        <FormInputField
          id="hierarchicalCoefficient"
          errors={errors.hierarchicalCoefficient}
          touched={touched.hierarchicalCoefficient}
          values={values.hierarchicalCoefficient}
          onChange={(e) => setFieldValue("hierarchicalCoefficient", e.target.value)}
          label={t("app.suivezVosContratsPartner.CoefficientHierarchique")}
        />
        <div className={styles.submit_btn_to_right}>
          <SubmitFormBtn text={t("app.visualisationOffre.ValidezLesModifications")} />
        </div>
      </form>
    </>
  );
};

export default SubForm8Other;
